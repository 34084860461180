import * as RadixTooltip from '@radix-ui/react-tooltip';
import { IconInfo } from '@fastly/beacon-icons';
import './tooltip.css';

type TooltipProps = {
  /*
  Must be wrapped in an interactive element to be keyboard accessible
  */
  children?: React.ReactNode;
  text: string;
};

const Tooltip = ({
  children = (
    <button type="button">
      <IconInfo />
    </button>
  ),
  text,
}: TooltipProps) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          <span className="tooltip-trigger">{children}</span>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className="tooltip-content">
            {text}
            <RadixTooltip.Arrow />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
